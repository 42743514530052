import request from '@/utils/request'

// 下拉标签
export function getTagDropdownList() {
  return request({
    url: '/api/tag/dropdownList',
    method: 'get'
  })
}

// 列表数量
export function getTagListCount(params) {
  return request({
    url: '/api/tag/list/count',
    method: 'get',
    params
  })
}

// 列表
export function getTagList(params) {
  return request({
    url: '/api/tag/list',
    method: 'get',
    params
  })
}


// 新建
export function createTag(data) {
  return request({
    url: '/api/tag/create',
    method: 'post',
    data
  })
}

// 编辑
export function updateTag(data) {
  return request({
    url: '/api/tag/update',
    method: 'post',
    data
  })
}

// 输入匹配
export function searchTagByName(params) {
  return request({
    url: '/api/tag/list/search/byName',
    method: 'get',
    params
  })
}

// 删除
export function deleteTag(data) {
  return request({
    url: '/api/tag/delete',
    method: 'post',
    data
  })
}
