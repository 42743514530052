<template>
  <div :class="{fullscreen:fullscreen}" class="tinymce-container" :style="{width:containerWidth}">
    <textarea :id="tinymceId" class="tinymce-textarea" @change="onPaste"></textarea>
  </div>
</template>

<script>
  import plugins from './plugins'
  import toolbar from './toolbar'
  import load from './dynamicLoadScript'
  // const tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one@5.6.1/tinymce.min.js'
  const tinymceCDN = '/tinymce/tinymce.min.js'
  import { uploadImage } from '@/api/app'

  export default {
    name: 'Tinymce',
    props: {
      id: {
        type: String,
        default: function() {
          return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
        }
      },
      value: {
        type: String,
        default: ''
      },
      toolbar: {
        type: Array,
        required: false,
        default() {
          return []
        }
      },
      menubar: {
        type: String,
        default: 'file edit insert view format table'
      },
      height: {
        type: [Number, String],
        required: false,
        default: 360
      },
      width: {
        type: [Number, String],
        required: false,
        default: 'auto'
      },
      languageType: {
        type: String,
        required: false,
        default: 'zh'
      }
    },
    data() {
      return {
        hasChange: false,
        hasInit: false,
        tinymceId: this.id,
        fullscreen: false,
        languageTypeList: {
          'en': 'en',
          'zh': 'zh_CN',
          'es': 'es_MX',
          'ja': 'ja'
        }
      }
    },
    computed: {
      containerWidth() {
        const width = this.width
        if (/^[\d]+(\.[\d]+)?$/.test(width)) { // matches `100`, `'100'`
          return `${width}px`
        }
        return width
      }
    },
    watch: {
      value(val) {
        // 正则去掉base64编码到图片
        // val = val.replace(/<p><img\s?src="data.*?<\/p>/g, '')
        if (!this.hasChange && this.hasInit) {
          this.$nextTick(() =>
            window.tinymce.get(this.tinymceId).setContent(val || ''))
        }
      }
    },
    mounted() {
      this.init()
    },
    activated() {
      if (window.tinymce) {
        this.initTinymce()
      }
    },
    deactivated() {
      this.destroyTinymce()
    },
    destroyed() {
      this.destroyTinymce()
    },
    methods: {
      init() {
        // dynamic load tinymce from cdn
        load(tinymceCDN, (err) => {
          if (err) {
            this.$message.error(err.message)
            return
          }
          this.initTinymce()
        })
      },
      initTinymce() {
        const _this = this
        window.tinymce.init({
          selector: `#${this.tinymceId}`, // 绑定输入框
          language: this.languageTypeList[this.languageType], // 选择语言
          height: this.height, // 高度
          body_class: 'panel-body ',
          object_resizing: false,
          toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar, // 头部导航栏
          menubar: this.menubar, // 菜单
          plugins: plugins, // 插件
          fontsize_formats: '10px 12px 14px 16px 18px 24px 32px',
          importcss_append: true, 
          style_formats: [
              { title: '大标题', inline: 'strong', styles: {'font-size': '19px','color': '#000'}, deep: false },
              { title: '正文', block: 'p', styles: {'font-size': '16px','color': '#000'}, deep: true },
              { title: '图说', block: 'p', styles: {'font-size': '14px','color': 'gray','text-align': 'center'}, deep: true }
          ],
          style_formats_merge: true,
          style_formats_autohide: true,
          end_container_on_empty_block: true,
          powerpaste_word_import: 'clean',
          code_dialog_height: 450, // 插入代码对话框
          code_dialog_width: 1000,
          advlist_bullet_styles: 'square',
          advlist_number_styles: 'default',
          default_link_target: '_blank',
          paste_data_images: false, // 粘贴图片
          link_title: false,
          content_style:'body{font-family: "Microsoft Yahei","冬青黑体简体中文 w3","宋体", "Helvetica Neue", Helvetica, Arial, sans-serif;}body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td{margin:0;padding:0;}',
          nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
          init_instance_callback: editor => {
            if (_this.value) {
              editor.setContent(_this.value)
            }
            _this.hasInit = true
            editor.on('NodeChange Change KeyUp SetContent', () => {
              this.hasChange = true
              // const val = editor.getContent().replace(/<p><img\s?src="data.*?<\/p>/g, '')
              this.$emit('input', editor.getContent())
            })
            editor.on('paste', (evt) => {
              // 监听粘贴事件
              this.onPaste(evt)
            })
          },
          setup(editor) {
            editor.on('FullscreenStateChanged', (e) => {
              _this.fullscreen = e.state
            })
            editor.addButton('quickformat', {
              text: '一键排版',
              onclick: () => {
                const content = _this.FormatText (editor)
                editor.setContent(content)
              }
            })
          },
          // 图片上传处理函数
          images_upload_handler (blobInfo, success, failure) {
            let formData = new FormData()
            formData.set("file", blobInfo.blob())
            uploadImage(formData).then(res => {
              success(res.data.result)
            }).catch(error => {
              _this.$message.error('上传失败')
            })
          },
          convert_urls: false
        })
      },
      destroyTinymce() {
        const tinymce = window.tinymce.get(this.tinymceId)
        if (this.fullscreen) {
          tinymce.execCommand('mceFullScreen')
        }
        if (tinymce) {
          tinymce.destroy()
        }
      },
      setContent(value) {
        window.tinymce.get(this.tinymceId).setContent(value)
      },
      getContent() {
        window.tinymce.get(this.tinymceId).getContent()
      },
      imageSuccessCBK(arr) {
        // 添加按钮上传图片
        arr.forEach(v => window.tinymce.get(this.tinymceId).insertContent(`<img class="wscnph" src="${v.url}" >`))
      },
      onPaste(event) {
        // 实现图片粘贴上传
        const items = (event.clipboardData || window.clipboardData).items
        if (items[0].type.indexOf('image') !== -1) {
          console.log('粘贴的是图片类型')
          const file = items[0].getAsFile()
          const formData = new FormData()
          formData.append('file', file)
          uploadImage(formData).then(res => {
            window.tinymce.get(this.tinymceId).insertContent(`<img class="wscnph" src="${res.data.data.result}" >`)
          })
        } else {
          console.log('粘贴的不是图片，不能上传')
        }
      },
      FormatText (editor) {
        let myeditor = editor
        var tempimg = new Array();
        var temptable = new Array();
        var tempobject = new Array();

        var tmpDiv = document.createElement("DIV");
        var editorhtml = myeditor.getContent();
        editorhtml = editorhtml.replace(/<div style="page-break-after: always;?">\s*<span style="display: none;?">&nbsp;<\/span>\s*<\/div>/gi, '<p>[page]</p>');  //将div span标签替换为p 标签
        tmpDiv.innerHTML = editorhtml.replace(/&nbsp;/gi, '').replace(/<div/gi, '<p').replace(/<\/div/gi, '</p');     //移除空格标签，div替换为p标签。
        var tables = tmpDiv.getElementsByTagName("TABLE");
        if (tables != null && tables.length > 0) {
            for (var j = 0; j < tables.length; j++) {
                temptable[temptable.length] = tables[j].outerHTML;
            }
            var formattableCount = 0;
            for (var j = 0; j < tables.length;) {
                tables[j].outerHTML = "#FormatTableID_" + formattableCount + "#";
                formattableCount++;
            }
        }
        var objects = tmpDiv.getElementsByTagName("OBJECT");
        if (objects != null && objects.length > 0) {
            for (var j = 0; j < objects.length; j++) {
                tempobject[tempobject.length] = objects[j].outerHTML;
            }
            var formatobjectCount = 0;
            for (var j = 0; j < objects.length;) {
                objects[j].outerHTML = "#FormatObjectID_" + formatobjectCount + "#";
                formatobjectCount++;
            }
        }
        var imgs = tmpDiv.getElementsByTagName("IMG");
        if (imgs != null && imgs.length > 0) {
            for (var j = 0; j < imgs.length; j++) {
                var t = document.createElement("IMG");
                t.alt = imgs[j].alt;
                t.src = imgs[j].src;
                t.width = imgs[j].width;
                t.height = imgs[j].height;
                t.align = imgs[j].align;
                tempimg[tempimg.length] = t;
            }
            var formatImgCount = 0;
            
            for (j = 0; j < imgs.length; ) {
              imgs[j].outerHTML = "\n#FormatImgID_" + formatImgCount + "#\n";
              formatImgCount++;
            }
        }
        
        var strongarray = new Array();
        var strongcount = 0;
        for (var i = 0; i < tmpDiv.getElementsByTagName('b').length; i++) {
            strongarray[strongcount] = tmpDiv.getElementsByTagName('b')[i].innerText.trim();
            tmpDiv.getElementsByTagName('b')[i].innerHTML = "#FormatStrongID_" + strongcount + "#";
            strongcount++;
        }
        for (var i = 0; i < tmpDiv.getElementsByTagName('strong').length; i++) {
            strongarray[strongcount] = tmpDiv.getElementsByTagName('strong')[i].innerText.trim();
            tmpDiv.getElementsByTagName('strong')[i].innerHTML = "#FormatStrongID_" + strongcount + "#";
            strongcount++;
        }
        var html = this.processFormatText(tmpDiv.innerText);

        html = html.replace(/<p>\[page\]<\/p>/gi, '<div style="page-break-after: always;"><span style="display: none;">&nbsp;</span></div>');   //p标签替换回原来的div和span标签。
        if (temptable != null && temptable.length > 0) {
            for (var j = 0; j < temptable.length; j++) {
                var tablehtml = temptable[j];
                html = html.replace("#FormatTableID_" + j + "#", tablehtml);
            }
        }
        if (tempobject != null && tempobject.length > 0) {
            for (var j = 0; j < tempobject.length; j++) {
                var objecthtml = "<p align=\"center\">" + tempobject[j] + "</p>";
                html = html.replace("#FormatObjectID_" + j + "#", objecthtml);
            }
        }
        if (tempimg != null && tempimg.length > 0) {
          
            for (var j = 0; j < tempimg.length; j++) {
                var imgheight = "";
                var imgwidth = "";
                if (tempimg[j].height != 0)
                    imgheight = " height=\"" + tempimg[j].height + "\"";
                if (tempimg[j].width != 0)
                    imgwidth = " width=\"" + tempimg[j].width + "\"";
                var imgalign = "";
                if (tempimg[j].align != "")
                    imgalign = " align=\"" + tempimg[j].align + "\"";
                var imghtml = "<p align=\"center\"><img src=\"" + tempimg[j].src + "\" alt=\"" + tempimg[j].alt + "\"" + imgwidth + " " + imgheight + " align=\"" + tempimg[j].align + "\" border=\"0\"></p>";
                html = html.replace("#FormatImgID_" + j + "#", imghtml);
            }
        }
        for (var i = 0; i < strongcount; i++) {
            html = html.replace("#FormatStrongID_" + i + "#", "<strong>" + strongarray[i] + "</strong>");
        }
        while (html.indexOf("</p></p>") != -1) html = html.replace("</p></p>", "</p>");
        while (html.indexOf('<p><p align="center">') != -1) html = html.replace('<p><p align="center">', '<p align="center">');
        return html;
      },
      processFormatText(textContext) {
          var text = this.dbc2Sbc(textContext);
          var prefix = "";
          var tmps = text.split("\n");
          var html = "";
          for (var i = 0; i < tmps.length; i++) {
              var tmp = tmps[i].trim();
              if (tmp.length > 0) {
                  // var reg = /#Format[A-Za-z]+_\d+#/gi;
                  // var f = reg.exec(tmp);
                  // if (f != null) {
                  //     tmp = tmp.replace(/#Format[A-Za-z]+_\d+#/gi, '');
                  //     html += f;
                  //     if (tmp != "")
                  //         html += "<p align=\"center\">" + tmp + "</p>\n";
                  // } else {
                  //     html += "<p style='text-indent:2em;font-size:14px;line-height:24px;margin-bottom:10px;'>" + tmp + "</p>\n";
                  // }
                  html += "<p style='text-indent:2em;font-size:16px;color:#333;line-height:30px;margin-bottom:26px;'>" + tmp + "</p>\n";
              }
          }
          return html;
      },
      dbc2Sbc(str) {
          var result = '';
          for (var i = 0; i < str.length; i++) {
              var code = str.charCodeAt(i);
              // “65281”是“！”，“65373”是“｝”，“65292”是“，”。不转换"，"
              if (code >= 65281 && code < 65373 && code != 65292 && code != 65306) {
                  //  “65248”是转换码距
                  result += String.fromCharCode(str.charCodeAt(i) - 65248);
              } else {
                  result += str.charAt(i);
              }
          }
          return result;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tinymce-container {
    position: relative;
    line-height: normal;
  }
  .tinymce-container {
    ::v-deep {
      .mce-fullscreen {
        z-index: 10000;
      }
    }
  }
  .tinymce-textarea {
    visibility: hidden;
    z-index: -1;
  }
  .editor-custom-btn-container {
    position: absolute;
    right: 4px;
    top: 4px;
    /*z-index: 2005;*/
  }
  .fullscreen .editor-custom-btn-container {
    z-index: 10000;
    position: fixed;
  }
  .editor-upload-btn {
    display: inline-block;
  }
</style>