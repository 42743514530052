import request from '@/utils/request'

// 获取文章列表数量
export function getArticleListCount(params) {
  return request({
    url: '/api/content/list/count',
    method: 'get',
    params
  })
}

// 获得文章列表
export function getArticleList(params) {
  return request({
    url: '/api/content/list',
    method: 'get',
    params
  })
}


// 修改状态
export function updateArticleStatus(data) {
  return request({
    url: '/api/content/updateStatus',
    method: 'post',
    data
  })
}

// 获得文章详情
export function getArticleDetail(params) {
  return request({
    url: '/api/content/detail',
    method: 'get',
    params
  })
}

// 新建文章
export function createArticle(data) {
  return request({
    url: '/api/content/create',
    method: 'post',
    data
  })
}

// 修改文章
export function updateArticle(data) {
  return request({
    url: '/api/content/update',
    method: 'post',
    data
  })
}

// 文章标题输入匹配
export function searchArticleByName(params) {
  return request({
    url: '/api/content/list/search/byName',
    method: 'get',
    params
  })
}

// 下载报表
export function exportArticle(params) {
  return request({
    url: '/api/content/list/export',
    method: 'get',
    params,
    responseType: 'blob'
  })
}

// 推送至幻灯片
export function sentToBanner(params) {
  return request({
    url: '/api/content/sentToBanner',
    method: 'get',
    params
  })
}

// 下载报表-列表
export function getDownloadList(params) {
  return request({
    url: '/api/content/download/list',
    method: 'get',
    params
  })
}

// 下载报表-列表数量
export function getDownloadListCount(params) {
  return request({
    url: '/api/content/download/list/count',
    method: 'get',
    params
  })
}

// 下载报表-导出
export function exportDownload(params) {
  return request({
    url: '/api/content/download/listAll',
    method: 'get',
    params,
    responseType: 'blob'
  })
}

