<template>
  <div class="edit-container" v-loading="loading == 'loading'">
    <el-form
      label-width="100px"
      class="edit-form"
      :model="formData"
      :rules="formRules"
      ref="form"
    >
      <el-form-item label="栏目:" prop="columnId">
        <el-select v-model="formData.columnId">
          <el-option
            :label="item.columnName"
            :value="item.columnId"
            v-for="item in columnList"
            :key="item.columnId"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="标题：" prop="title">
        <el-input
          placeholder="请输入50字以内"
          maxlength="50"
          v-model="formData.title"
        ></el-input>
      </el-form-item>

      <el-form-item label="封面图：" prop="thumb">
        <upload-image v-model="formData.thumb"></upload-image>
        <div class="upload-tip">
          尺寸：230*153，上传图片格式为jpg、png，图片大小不超过15M
        </div>
      </el-form-item>

      <el-form-item label="摘要" prop="intro">
        <el-input type="textarea" v-model="formData.intro"></el-input>
      </el-form-item>

      <el-form-item label="作者：" prop="author">
        <el-input v-model="formData.author" style="width:200px"></el-input>
      </el-form-item>

      <el-form-item label="标签：" prop="tagIdList">
        <el-select
          v-model="formData.tagIdList"
          multiple
          filterable
          remote
          :loading="loading == 'select'"
          :remote-method="handleMatchTag"
          @keyup.enter.native="handleCreateTag"
        >
          <el-option
            v-for="item in tagOptions"
            :key="item.tagId"
            :label="item.tagName"
            :value="item.tagId"
          >
          </el-option>
          <div class="select-tip" v-if="selectTip">
            只显示前10条，请完善关键字
          </div>
        </el-select>
      </el-form-item>

      <el-form-item label="发布时间：" v-if="formData.releaseTime">
        <el-input
          :disabled="true"
          v-model="formData.releaseTime"
          style="width:200px"
        ></el-input>
      </el-form-item>

      <el-form-item label="阅读量：" v-if="formData.realReadNum">
        <el-input
          :disabled="true"
          v-model="formData.realReadNum"
          style="width:200px"
        ></el-input>
      </el-form-item>

      <el-form-item label="文章内容：" prop="content">
        <tinymce ref="editor" v-model="formData.content" />
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          :loading="loading == 'draft'"
          @click="handleSubmit('draft')"
          >草稿</el-button
        >
        <el-button
          type="primary"
          :loading="loading == 'publish'"
          @click="handleSubmit('publish')"
          >{{ sure }}</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UploadImage from "@/components/UploadImage";
import Tinymce from "@/components/Tinymce";
import { getArticleDetail, createArticle, updateArticle } from "@/api/article";
import { getTagDropdownList, searchTagByName, createTag } from "@/api/tag";
import { getColumnDropdownList } from "@/api/column";

import { parseTime } from "@/utils/tool.js";

export default {
  name: "edit",
  components: {
    UploadImage,
    Tinymce,
  },
  data() {
    return {
      columnName: "",
      columnList: [],
      columnId: this.$route.meta.columnId,
      contentId: this.$route.query.contentId,
      formData: {},
      loading: false,
      tagList: [],
      tagOptions: [],
      formRules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        thumb: [{ required: true, message: "请上传封面图", trigger: "blur" }],
        intro: [{ required: true, message: "请输入摘要", trigger: "blur" }],
        tagIdList: [{ required: true, message: "请输入标签", trigger: "blur" }],
        content: [
          { required: true, message: "请输入文章内容", trigger: "blur" },
        ],
      },
      tagDisabled: false,
      imagecropperShow: true,
      imagecropperKey: 0,
      image: "https://wpimg.wallstcn.com/577965b9-bb9e-4e02-9f0c-095b41417191",
      matchTag: null,
      selectTip: false,
      sure: "",
    };
  },
  created() {
    if (this.contentId) {
      this.loading = "loading";
      this.getDetail();
    }
    this.getColumnList();
  },
  mounted() {
    if (this.contentId === undefined) {
      this.sure = "发布";
    } else {
      this.sure = "保存";
    }
  },
  methods: {
    getColumnList() {
      getColumnDropdownList().then((res) => {
        this.columnList = res.data.result;
      });
    },

    getDetail() {
      getArticleDetail({ contentId: this.contentId }).then((res) => {
        if (!res.data.contentId) {
          this.contentId = null;
          return;
        }

        const {
          title,
          thumb,
          intro,
          author,
          content,
          releaseTime,
          realReadNum,
        } = res.data;
        this.formData = {
          title,
          thumb,
          intro,
          author,
          content,
          releaseTime,
          realReadNum,
        };
        this.formData.releaseTime = parseTime(
          this.formData.releaseTime,
          "{y}-{m}-{d} {h}:{i}"
        );
        this.formData.columnId = res.data.column.columnId;
        this.formData.tagIdList = res.data.tagList.map((item) => {
          return item.tagId;
        });

        this.formData = { ...this.formData };
        this.tagOptions = res.data.tagList;

        this.loading = false;
      });
    },

    // 匹配标签
    handleMatchTag(query) {
      const queryStr = query.trim();
      if (queryStr !== "") {
        this.loading = "select";
        searchTagByName({ tagName: query }).then((res) => {
          this.loading = false;
          const arr = res.data.result;
          let matchTag;
          for (var i = 0; i < arr.length; i++) {
            if (arr[i].tagName === queryStr) {
              matchTag = arr[i];
              arr.splice(i, 1);
              break;
            }
          }
          if (matchTag) {
            this.matchTag = matchTag;
            arr.unshift(this.matchTag);
          } else {
            this.matchTag = null;
          }
          if (arr.length > 10) {
            this.selectTip = true;
            arr.splice(10, arr.length - 10);
          } else {
            this.selectTip = false;
          }

          this.tagList = arr;
          this.tagOptions = this.tagList.filter((item) => {
            return (
              item.tagName.toLowerCase().indexOf(queryStr.toLowerCase()) > -1
            );
          });
        });
      } else {
        this.tagOptions = [];
        this.tagList = [];
        this.selectTip = false;
      }
    },
    handleCreateTag(e) {
      const newTag = e.target.value.trim();
      if (!this.matchTag && newTag !== "") {
        createTag({ tagName: newTag }).then(() => {
          this.handleMatchTag(newTag);
        });
      }
    },
    handleSubmit(type) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.tagOptions = [];
          this.tagList = [];
          this.loading = type;
          try {
            if (type == "draft") {
              // 草稿
              this.formData.releaseStatus = "WAIT_RELEASE";
            } else {
              // 发布
              this.formData.releaseStatus = "RELEASED";
            }
            let contentId;
            if (this.contentId) {
              // 修改

              this.formData.contentId = this.contentId;

              await updateArticle(this.formData);
              contentId = this.contentId;
            } else {
              // 新建
              this.formData.columnId = this.columnId;
              await createArticle(this.formData).then((res) => {
                contentId = res.data.result;
              });
            }
            this.loading = false;
            this.$message({
              message: "保存成功",
              type: "success",
              duration: 1000,
              onClose: () => {
                if (this.formData.columnId != this.columnId) {
                  const aid = this.formData.columnId;
                  this.$router.push(
                    "/presscenter/article" +
                      aid +
                      "/detail?contentId=" +
                      contentId
                  );
                } else {
                  const path = this.$route.matched.slice(
                    this.$route.matched.length - 2,
                    this.$route.matched.length - 1
                  )[0].path;

                  this.$router.push(path + "/detail?contentId=" + contentId);
                }
              },
            });
          } catch {
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    },
    cropSuccess(resData) {
      this.imagecropperShow = false;
      this.imagecropperKey = this.imagecropperKey + 1;
      this.image = resData.files.avatar;
    },
    close() {
      this.imagecropperShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-scrollbar__wrap {
  margin-bottom: 15px;
}
.select-tip {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  font-size: 12px;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}
</style>
