import request from '@/utils/request'

// 栏目下拉
export function getColumnDropdownList(params) {
  return request({
    url: '/api/column/dropdownList',
    method: 'get',
    params
  })
}

// 列表数量
export function getColumnListCount(params) {
  return request({
    url: '/api/column/list/count',
    method: 'get',
    params
  })
}

// 列表
export function getColumnList(params) {
  return request({
    url: '/api/column/list',
    method: 'get',
    params
  })
}